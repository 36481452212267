import React, { useState } from "react";
import {
  Container,
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Heading,
  InputRightElement,
  InputGroup,
  Button,
  IconButton,
  Image,
  HStack,
  Stack,
  Center,
  Square,
  Flex,
  Spacer,
  StackDivider,
  Divider,
  LinkBox,
  LinkOverlay,
  Badge,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import theme from "./theme";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
// import { Logo } from "./Logo";
// import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import Copied from "./components/Copied";
// import Tinkoff from "./tinkoff.svg";
// import Sber from "./sber.png";
import Avatar from "./zhokhov.jpg";
import { useSearchParams } from "react-router-dom";

export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const App = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const success = searchParams.get("success");

  const cardNumber = "4111111111111111";
  const phone = "+79222222222";
  const amount = "100500";

  return (
    <ChakraProvider theme={theme}>
      <Container mb="20" fontFamily="Courier New">
        <VStack align="start" spacing={5} sx={{ mt: 4 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
          >
            <Heading as="h1" fontSize="3xl" fontFamily="Montserrat">
              Перевод
            </Heading>

            <ColorModeSwitcher />
          </Stack>
          <Divider variant="dashed" />

          <Heading
            as="h2"
            fontSize="2xl"
            fontFamily="Montserrat"
            fontWeight="normal"
          >
            1. Получатель
          </Heading>
          <Stack direction={["column", "row"]} spacing={["1", "5"]} mb="5">
            <Box flexShrink={0}>
              <Image
                borderRadius="full"
                objectFit="cover"
                maxW="100px"
                src={Avatar}
                alt="Avatar"
              />
              <Box className="chakra-avatar__badge css-3cebbl"></Box>
            </Box>
            <Stack direction="column">
              <Heading as="h2" fontSize="2xl">
                Егор Жохов
                <Icon
                  viewBox="0 0 16 16"
                  color="blue.200"
                  sx={{ display: "inline !important" }}
                  ml="2"
                  stroke-width="0"
                  focusable="false"
                  height="1em"
                  width="1em"
                >
                  <path
                    stroke="currentColor"
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M15.67 7.066l-1.08-1.34a1.5 1.5 0 0 1-.309-.77l-.19-1.698a1.51 1.51 0 0 0-1.329-1.33l-1.699-.19c-.3-.03-.56-.159-.78-.329L8.945.33a1.504 1.504 0 0 0-1.878 0l-1.34 1.08a1.5 1.5 0 0 1-.77.31l-1.698.19c-.7.08-1.25.63-1.33 1.329l-.19 1.699c-.03.3-.159.56-.329.78L.33 7.055a1.504 1.504 0 0 0 0 1.878l1.08 1.34c.17.22.28.48.31.77l.19 1.698c.08.7.63 1.25 1.329 1.33l1.699.19c.3.03.56.159.78.329l1.339 1.08c.55.439 1.329.439 1.878 0l1.34-1.08c.22-.17.48-.28.77-.31l1.698-.19c.7-.08 1.25-.63 1.33-1.329l.19-1.699c.03-.3.159-.56.329-.78l1.08-1.339a1.504 1.504 0 0 0 0-1.878zM6.5 12.01L3 8.51l1.5-1.5 2 2 5-5L13 5.56l-6.5 6.45z"
                  ></path>
                </Icon>
              </Heading>
              <Text mt="2">
                IT-предприниматель. Помогаю зарабатывать на том, что вы любите,
                с помощью касаний с клиентами.
              </Text>
              <Box>
                <Badge colorScheme="blue" px="3" textTransform="none">
                  Проверенный
                </Badge>
              </Box>
            </Stack>
          </Stack>

          <Divider variant="dashed" />

          <Heading
            as="h2"
            fontSize="2xl"
            fontFamily="Montserrat"
            fontWeight="normal"
          >
            2. Проверьте сумму
          </Heading>
          <Copied name="Сумма" value={`${amount} р.`} isCopy={false} />
          <Divider variant="dashed" />

          <Heading
            as="h2"
            fontSize="2xl"
            fontFamily="Montserrat"
            fontWeight="normal"
          >
            3. Скопируйте номер карты
          </Heading>
          <Copied
            name="Номер карты"
            value={cardNumber.match(/.{1,4}/g)?.join(" ")}
          />
          {/* <Copied
            name="Номер телефона"
            value={phone.replace(
              /(\+7)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
              "$1 $2 $3 $4 $5"
            )}
          /> */}
          <Divider variant="dashed" />

          <Heading
            as="h2"
            fontSize="2xl"
            fontFamily="Montserrat"
            fontWeight="normal"
          >
            4. Сделайте перевод
          </Heading>
          <Stack direction={["column", "row"]} spacing={5}>
            <LinkBox
              sx={{
                "&:hover": {
                  // boxShadow: "var(--chakra-shadows-dark-lg)",
                  // borderColor: "orange",
                  backgroundColor: "orange.200",
                  color: "black",
                },
              }}
              flexBasis={["0", "300px"]}
              p={5}
              borderWidth="1px"
              borderRadius="lg"
              borderColor="orange"
              shadow="lg"
            >
              <Text flexBasis="300px" align="center">
                <LinkOverlay
                  href="https://www.tinkoff.ru/cardtocard/"
                  isExternal
                >
                  Нажмите сюда, чтобы открыть сервис переводов на сайте банка
                  Тинькофф (быть клиентом не нужно, работают переводы с любых
                  рос. карт)
                </LinkOverlay>
              </Text>
            </LinkBox>
            <Center
              flexBasis={["0", "300px"]}
              p={5}
              borderWidth="1px"
              borderRadius="lg"
            >
              <Text align="center">
                Либо перейдите в онлайн-кабинет или приложение вашего банка
              </Text>
            </Center>
          </Stack>
        </VStack>
      </Container>
    </ChakraProvider>
  );
};

{
  /* android-app://com.idamob.tinkoff.android/android-app/ */
}
{
  /* <Link href="tinkoffbank://payment">
  <Image
    boxSize="100px"
    objectFit="cover"
    src={Tinkoff}
    alt="Приложение Тинькофф"
  />
</Link> */
}
{
  /* {`https://node4.online.sberbank.ru/PhizIC/private/payments/payment.do?form=RurPayment&externalCardNumber=${cardNumber}&buyAmount=${amount}`} */
}
{
  /* sberbankonline://payments/services/init/?ids=eyJjbiI6eyJiIjoiMjg0IiwibiI6ItCa0L7QvNC80YPQvdCw0LvRjNC90YvQtSDRg9GB0LvRg9Cz0LgiLCJwcyI6IjU1MDM2NTg4MCJ9LCJucyI6eyJub2RlMC5vbmxpbmUuc2JlcmJhbmsucnUiOnsicHMiOiI1MDAzMzQ1MDAifSwibm9kZTEub25saW5lLnNiZXJiYW5rLnJ1Ijp7InBzIjoiNTE2OTM5In0sIm5vZGUyLm9ubGluZS5zYmVyYmFuay5ydSI6eyJwcyI6IjUwMDMzMzk5NSJ9LCJub2RlMy5vbmxpbmUuc2JlcmJhbmsucnUiOnsicHMiOiI1MDAzMzMxNjQifX0sImF0Ijp0cnVlfQ==
sberbankonline://payments/services/init/?ids=eyJjbiI6eyJiIjoiMjg0IiwibiI6ItCf0LvQsNGC0L3Ri9C1INC-0LHRgNCw0LfQvtCy0LDRgtC10LvRjNC90YvQtSDRg9GB0LvRg9Cz0LgiLCJwcyI6IjU1MDQyNDcyNyJ9LCJucyI6eyJub2RlMC5vbmxpbmUuc2JlcmJhbmsucnUiOnsicHMiOiI1MDAzOTMyNjgifSwibm9kZTEub25saW5lLnNiZXJiYW5rLnJ1Ijp7InBzIjoiNTc1Nzc0In0sIm5vZGUyLm9ubGluZS5zYmVyYmFuay5ydSI6eyJwcyI6IjUwMDM5MjgxOCJ9LCJub2RlMy5vbmxpbmUuc2JlcmJhbmsucnUiOnsicHMiOiI1MDAzOTIwMjQifX0sImF0IjpmYWxzZX0=
android-app://ru.sberbankmobile/payments/p2p?type=card_number */
}
{
  /* sberbankonline://payments/p2p?type=card_number
services/init?ids=eyJjbiI6eyJiIjoiMjg0IiwibiI6ItCf0LvQsNGC0L3Ri9C1INC-0LHRgNCw0LfQvtCy0LDRgtC10LvRjNC90YvQtSDRg9GB0LvRg9Cz0LgiLCJwcyI6IjU1MDQyNDcyNyJ9LCJucyI6eyJub2RlMC5vbmxpbmUuc2JlcmJhbmsucnUiOnsicHMiOiI1MDAzOTMyNjgifSwibm9kZTEub25saW5lLnNiZXJiYW5rLnJ1Ijp7InBzIjoiNTc1Nzc0In0sIm5vZGUyLm9ubGluZS5zYmVyYmFuay5ydSI6eyJwcyI6IjUwMDM5MjgxOCJ9LCJub2RlMy5vbmxpbmUuc2JlcmJhbmsucnUiOnsicHMiOiI1MDAzOTIwMjQifX0sImF0IjpmYWxzZX0=
*/
}
{
  /* https://online.sberbank.ru/CSAFront/index.do#/ */
}
{
  /*  */
}
{
  /* <Link href="android-app://ru.sberbankmobile/android-app/ru.sberbankmobile/payments/p2p?type=card_number">
  <Image
    boxSize="100px"
    objectFit="cover"
    src={Sber}
    alt="Приложение СберОнлайн"
  />
</Link> */
}
