import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

const colors = {
  borderColor: "white",
};

const theme = extendTheme({ config, colors });

export default theme;
