import { CopyIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  InputGroup,
  Input,
  InputLeftElement,
  IconButton,
  Button,
  HStack,
  GridItem,
  Grid,
  Stack,
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

function Copied({ name, value, isCopy = true }: any) {
  const [isCopied, setIsCopied] = useState(false);

  const copy = (text: string) => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500);
  };

  return (
    <FormControl>
      {/* templateRows="50px 1fr 30px" */}
      <Grid
        alignItems="center"
        templateColumns={[`120px 1fr`, `120px 1fr ${isCopy ? "120px" : ""}`]}
        templateAreas={[
          `"label input" ${isCopy ? '"button button"' : ""}`,
          `"label input ${isCopy ? "button" : ""}"`,
        ]}
        gap={4}
        mb="5"
      >
        <GridItem area="label">
          {/* as="div" alignSelf="center" flexGrow={0} */}
          <Text>{name}</Text>
        </GridItem>

        <GridItem area="input">
          <Input
            type="text"
            variant="filled"
            size="sm"
            value={value}
            onClick={() => copy(value)}
            isReadOnly
          />
        </GridItem>

        {isCopy && (
          <GridItem area="button">
            <Button
              isFullWidth
              colorScheme={isCopied ? "green" : "orange"}
              size="sm"
              onClick={() => copy(value)}
            >
              {isCopied ? "Скопировано" : "Скопировать"}
            </Button>
          </GridItem>
        )}
      </Grid>
    </FormControl>
  );
}

export default Copied;

{
  /*<InputGroup>
 <InputLeftElement width="8rem">
  <Button
    ml="2"
    isFullWidth
    colorScheme={isCopied ? "green" : "orange"}
    size="sm"
    onClick={() => copy(value)}
  >
    {isCopied ? "Скопировано" : "Скопировать"}
  </Button>
</InputLeftElement>
<Input
  id={name}
  type="text"
  variant="filled"
  value={value}
  onClick={() => copy(value)}
  pl="9rem"
  isReadOnly
/>
</InputGroup> */
}
